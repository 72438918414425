import { Adobe, AdobeVM } from '@fc/sponsor_front';
import { cookie } from 'Project/ts/library/cookie';

export const getAdobePopup = async () => {
    const searchTerm = document.querySelector<HTMLInputElement>('.main_search_bar input[name="word"]')?.value || 'icon';
    const sponsorResponse = (await Adobe({
        placement: 'popup',
        country: USER_COUNTRY_CODE || 'us',
        projectId: 'www.flaticon.com',
        userId: cookie.getItem('_ga') || '',
        components: 'popup',
        redirectAndBase64: '//flat.toolsmeen.io/edge/redirect?url=',
        categoryType: 'icon' || '',
        params: {
            words: `${searchTerm} icon`,
            language: LANGUAGE_SHORT,
            orientation: 'square',
            type: ['vector'],
            image_type: 'vector',
            page: document.getElementById('pagination-page')
                ? parseInt((document.getElementById('pagination-page') as HTMLInputElement)?.value)
                : 1,
        },
        locale: USER_COUNTRY_CODE || 'us',
        type: 'vector',
    })) as AdobeVM.AdobeResponsePopup;
    return sponsorResponse;
};

export const printAdobeCustomPixel = (params: string) => {
    new Image().src = `${BASE_URL}_ga?${params}&r=${new Date().getTime()}`;
};
