import { Istock, IstockVM } from '@fc/sponsor_front';
import { cookie } from 'Project/ts/library/cookie';

export const getIstockPopup = async () => {
    const searchTerm = document.querySelector<HTMLInputElement>('.main_search_bar input[name="word"]')?.value || 'icon';
    const sponsorResponse = (await Istock({
        placement: 'popup',
        country: USER_COUNTRY_CODE || 'us',
        projectId: 'www.flaticon.com',
        userId: cookie.getItem('_ga') || '',
        components: 'popup',
        redirectAndBase64: '//flat.toolsmeen.io/edge/redirect?url=',
        categoryType: 'icon' || '',
        params: {
            phrase: `${searchTerm} icon`,
            language: LANGUAGE_SHORT,
        },
        locale: USER_COUNTRY_CODE || 'us',
        type: 'vector',
    })) as IstockVM.iStockResponsePopup;
    return sponsorResponse;
};

export const printIstockCustomPixel = (params: string) => {
    new Image().src = `${BASE_URL}_ga?${params}&r=${new Date().getTime()}`;
};
